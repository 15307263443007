import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Selectbar from "./Selectbar.tsx";
import CardOptions from "./CardOptions.tsx";
import SelectField, { SelectItemProps } from "./../../components/common/SelectField.tsx";
import LinkButton from "./../../components/common/LinkButton.tsx";
import TextField from "./../../components/common/TextField";
import SliderLevel from "./../../components/common/SliderLevel";
import ReactSlider from "react-slider";
import "../../pages/NewChallengeConfirmationDetails/slider.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import WagePayment from "../../utils/WagePayment";
import axios from "axios";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Competitions from "../Competitions/Competitions";
import { useAuthUser } from "react-auth-kit";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Sports from "../NewChallenge/sports.json";
import types from "../NewChallenge/types.json";
import categories from "../NewChallenge/categories.json";
import Fitness from "../NewChallenge/Fitness.json";
import Finance from "../NewChallenge/Finance.json";
import Habits from "../NewChallenge/Habits.json";
import Learning from "../NewChallenge/Learning.json";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/Loader/loader.jsx";
import Modal from "react-modal";
import { Form } from "react-bootstrap";
import "./confirmDetails.css";

type LocationState = {
  selectedItems: SelectItemProps[];
};

export default function ConfirmDetails() {
  const [loaderChecker, setLoaderChecker] = useState(false);
  const navigate = useNavigate();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");
  const authUser = useAuthUser();
  const [competitionName, setCompetitionsName] = useState();
  const [wager, setWager] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [level, setLevel] = useState<number>(10);
  const [status, setStatus] = useState(0);
  const [isEnabled, setIsEnabled] = useState(true);
  const [goal, setGoal] = useState(0);
  const inputRef = useRef(null);
  const [postImage, setPostImage] = useState([]);
  const [postVideo, setPostVideo] = useState(null);
  const [url, setUrl] = useState({});
  const [videoName, setVideoName] = useState("");
  let percent = 0;
  const toastId = useRef(null);
  const [videoInfo,setVideoInfo] = useState({
    url:"",
    flag:false,
  })

  const location = useLocation();
  const { selectedItems } = location.state as LocationState;
  const [measurement, setMeasurement] = useState(0);
  const [direction, setDirection] = useState();
  const [decidingWinner, setDecidingWinner] = useState(true);
  const [otherUserDetail, setOtherUserDetail] = useState("");

  // console.log(authUser().user_id);

  const selectOptionfun = () => {
    if (selectedItems[1].name == "Sports") {
      return Sports.items[selectedItems[2].id].items == undefined
        ? []
        : Sports.items[selectedItems[2].id].items;
    } else if (selectedItems[1].name == "Learning") {
      return Learning.items[selectedItems[2].id].items == undefined
        ? []
        : Learning.items[selectedItems[2].id].items;
    } else if (selectedItems[1].name == "Fitness") {
      return Fitness.items[selectedItems[2].id].items == undefined
        ? []
        : Fitness.items[selectedItems[2].id].items;
    } else if (selectedItems[1].name == "Finance") {
      return Finance.items[selectedItems[2].id].items == undefined
        ? []
        : Finance.items[selectedItems[2].id].items;
    } else if (selectedItems[1].name == "Habits") {
      return [];
    }
  };

  const config = {
    onUploadProgress: (progressEvent:any) => {
      const { loaded, total } = progressEvent;
      percent = loaded / total;
      if (toastId.current === null) {
        toastId.current = toast("Upload in Progess", { percent });
      } else {
        toast.update(toastId.current, { percent });
      }
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files && event.target.files[0];
    console.log(event.target.files[0]);
    setVideoName(event.target.files[0].name);
    if (selectedFile) {
      setPostImage([]);
      setPostVideo(event.target.files[0]);
      let formData = new FormData();
      formData.append("image", event.target.files[0]);
      axios
        .post(`${process.env.REACT_APP_API_URL}/upload`, formData, config)
        .then((res) => {
          setVideoInfo({...videoInfo, flag: false,url: res.data.url});
      })
      const localURL = URL.createObjectURL(event.target.files[0]);
      setUrl(localURL);
    } else {
      return;
    }
    event.target.value = null;
  };

  const handleSelectChange = (e) => {
    console.log("Change", e);
    setMeasurement(e.id);
  };

  const handleDirectionChange = (e) => {
    console.log("Change", e);
    setDirection(e.id);
  };

  const handleDecidingWinner = (e) => {
    console.log("Change", e);
    setDecidingWinner(e.label);
  };

  // const handleChallengeBy = (e) => {
  //   console.log("Change", e);
  //   setchallengeBy(e.label);
  // };

  const [options, setOptions] = useState(selectOptionfun);
  const [directionOptions, setDirectionOptions] = useState([
    { id: true, label: "Up" },
    { id: false, label: "Down" },
  ]);
  const [decidingWinnerOptions, setDecidingWinnerOptions] = useState([
    { id: 1, label: "Percentage" },
    { id: 2, label: "Average" },
    { id: 3, label: "Total" },
  ]);

  const today = new Date().toISOString().split("T")[0];
  const date = new Date();

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  // Stripe
  const stripe = useStripe();
  const elements = useElements();

  const selected = {
    items: selectedItems,
  };

  const handleChange = (event:any) => {
    setCompetitionsName(event.target.value);
  };

  const handleStartChange = (event:any) => {
    // console.log(endDate , event.target.value, "dates")
    // if(Date.parse(event.target.value) < Date.parse(endDate)){
    //   return toast.warning("Please enter proper date");
    // }
    return setStartDate(event.target.value);
  };

  const handleEndChange = (event:any) => {
    setEndDate(event.target.value);
  };

  const handleStatusChange = (event:any) => {
    setStatus(event.target.value);
  };

  const handleGoalChange = (event:any) => {
    setGoal(event.target.value);
  };

  const handleWagerChange = (event) => {
    setWager(event.target.value);
  };

  const handleOtherUser = (event) => {
    setOtherUserDetail(event.target.value);
  };

  const customStylesModalLoader = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      border: "none !important",
      background: "none !important",
      overflow: "none !important",
    },
  };

  useEffect(() => {
    document.title = "Confirm Details";
  }, []);

  const createCompetition = async (e) => {
    console.log(decidingWinner);
    console.log(direction);

    if (
      competitionName == null ||
      competitionName == 0 ||
      competitionName == undefined
    ) {
      toast.warning("Please enter competition title");
      return;
    }

    if (wager == null || wager == 0 || wager == undefined) {
      toast.warning("Please enter value of wager greater than 0");
      return;
    }

    if (startDate == null || startDate == 0 || startDate == undefined) {
      toast.warning("Please enter start date");
      return;
    }

    if (endDate == null || endDate == 0 || endDate == undefined) {
      toast.warning("Please enter end date");
      return;
    }

    if (status == null || status == undefined) {
      toast.warning("Please enter starting value");
      return;
    }

    if (goal == null || goal == undefined) {
      toast.warning("Please set goal value");
      return;
    }

    if (postVideo == null || postVideo.size == 0 || postVideo == undefined) {
      toast.warning("Please upload a video to continue");
      return;
    }

    if (
      (direction == null || direction == undefined) &&
      selectedItems[0].id !== 0
    ) {
      toast.warning("Please choose direction to continue");
      return;
    }

    // if (
    //   decidingWinner == null ||
    //   decidingWinner == "" ||
    //   decidingWinner == undefined
    // ) {
    //   toast.warning("Please select deciding winner");
    //   return;
    // }

    // if (direction == null || direction == "" || direction == undefined) {
    //   toast.warning("Please select direction");
    //   return;
    // }

    console.log("postVideo", postVideo);

    e.preventDefault();
    //return;

    setLoaderChecker(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      return alert(error.message);
    }

    const { id } = paymentMethod;

    axios
      .post(`${process.env.REACT_APP_API_URL}/payments`, {
        user: authUser().user_id,
        amount: wager,
        stripe_id: id,
        description: competitionName,
        date: Date.now(),
      })
      .then(() => {
        axios.post(`${process.env.REACT_APP_API_URL}/challenges`, {
          user: authUser()?.user_id,
          direction: selectedItems[0].id == 0 ? true : direction,
          name: competitionName,
          image: videoInfo.url,
          type: selectedItems[0].id,
          category: selectedItems[1].id,
          sub_category: selectedItems[2].id,
          measurement: measurement,
          calculate_by: "Percentage",
          goal: goal,
          forOther: selectedItems[0].id == 0 ? isEnabled : true,
          identity: otherUserDetail,
          start_date: startDate,
          end_date: endDate,
          wager: wager,
          team: null,
          participants:
            isEnabled == true && selectedItems[0].id == 0
              ? []
              : [
                  {
                    participant_id: authUser().user_id,
                    team_id: null,
                    start_measurement: status,
                    date_joined: new Date(),
                  },
                ],
        })
        .then((res) => {
          setLoaderChecker(false);
          navigate(`/competition/${res.data._id}`);
        })
        .catch((err) => {
          alert(err);
          setLoaderChecker(false);
        });
       
          });
      
  };

  return (
    <main>
      <div className="lg:grid lg:grid-cols-2 lg:flex-row flex-col-reverse container mt-20 gap-7 items-center">
        <div className="shadow-1xl p-2.5 bg-white rounded-lg">
          <SelectField variant="small" readonly {...selected} />
        </div>
        <Selectbar />
      </div>
      <div className="container shadow-1xl px-6 pt-5 pb-4 mt-4 mb-8 bg-white">
        <div className="grid lg:grid-cols-5 gap-[75px] mb-8">
          <div className="lg:col-span-3">
            <div className="d-flex flex-row justify-content-between">
              <h4 className="font-medium text-32xl mb-4">Information</h4>
              <Form
                style={{ display: selectedItems[0].id == 0 ? "block" : "none" }}
                className="font-medium text-32xl mb-4"
              >
                <Form.Check
                  type="switch"
                  defaultChecked={isEnabled}
                  onChange={() => {
                    setIsEnabled(!isEnabled);
                    setOtherUserDetail("");
                    setGoal(0);
                    setStatus(0);
                  }}
                  label="For Other"
                  id="custom-switch"
                />
              </Form>
            </div>
            <div className="bg-white shadow-3xl px-8 sm:px-14 py-6 rounded-md">
              <div className="grid sm:grid-cols-2 gap-14">
                <div className="flex flex-col gap-11">
                  <div className="flex flex-col gap-2">
                    <label htmlFor={"id1"} className="text-17xl font-medium">
                      Competition
                    </label>

                    <div className="relative flex-grow">
                      <input
                        id={"id2"}
                        value={competitionName}
                        type="text"
                        onChange={handleChange}
                        className="bg-yellow-100 border-primary-700 border border-opacity-25 text-primary-700 placeholder:text-primary-700 placeholder:text-opacity-40 rounded-full py-2 px-5 font-semibold text-5xl w-full"
                        placeholder={"Competion Name"}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-5 items-end">
                    <div className="flex flex-col gap-2">
                      <label htmlFor={"id1"} className="text-17xl font-medium">
                        Start Date
                      </label>

                      <div className="relative flex-grow">
                        <input
                          value={startDate}
                          type="date"
                          min={today}
                          onChange={handleStartChange}
                          className="bg-yellow-100 border-primary-700 border border-opacity-25 text-primary-700 placeholder:text-primary-700 placeholder:text-opacity-40 rounded-full py-2 px-5 font-semibold text-5xl w-full"
                          placeholder={"From"}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <label htmlFor={"id1"} className="text-17xl font-medium">
                        End Date
                      </label>
                      {new Date(endDate) < new Date(startDate) ? (
                       <div className="warning-msg">
                       <i className="fa fa-warning"></i>
                       Warning!  Please Enter a greater End Date value.
                     </div>
                      ) : null}
                      <div className="relative flex-grow">
                        <input
                          value={endDate}
                          type="date"
                          min={startDate ? startDate : today}
                          onChange={handleEndChange}
                          className="bg-yellow-100 border-primary-700 border border-opacity-25 text-primary-700 placeholder:text-primary-700 placeholder:text-opacity-40 rounded-full py-2 px-5 font-semibold text-5xl w-full"
                          placeholder={"From"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span className="text-17xl font-medium">Level</span>
                    <div className="flex gap-4">
                      <ReactSlider
                        className="horizontal-slider"
                        thumbClassName="horizontal-slider-thumb"
                        trackClassName="horizontal-slider-track"
                        onChange={(value) => setLevel(value)}
                        value={level}
                      />
                      <div className="w-[80px] leading-[22px] text-right">
                        {level <= 15 ? (
                          <span className="text-red-800 font-medium text-14xl">
                            Very low
                          </span>
                        ) : level <= 30 ? (
                          <span className="text-red-800 font-medium text-14xl">
                            Low
                          </span>
                        ) : level <= 50 ? (
                          <span className="text-primary-700 font-medium text-14xl">
                            Medium
                          </span>
                        ) : level <= 75 ? (
                          <span className="text-green-700 font-medium text-14xl">
                            High
                          </span>
                        ) : (
                          <span className="text-green-700 font-medium text-14xl">
                            Very High
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-5 items-end">
                    <div
                      style={{
                        display:
                          isEnabled || selectedItems[0].id == 1
                            ? "block"
                            : "none",
                      }}
                      className="flex flex-col gap-2"
                    >
                      <label htmlFor={"id1"} className="text-17xl font-medium">
                        Enter Detail
                      </label>

                      <div className="relative flex-grow">
                        <input
                          id={"id2"}
                          type="text"
                          onChange={handleOtherUser}
                          value={otherUserDetail}
                          className="bg-yellow-100 border-primary-700 border border-opacity-25 text-primary-700 placeholder:text-primary-700 placeholder:text-opacity-40 rounded-full py-2 px-5 font-semibold text-5xl w-full"
                          placeholder={"Enter Detail"}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display:
                          !isEnabled || selectedItems[0].id !== 0
                            ? "block"
                            : "none",
                      }}
                      className="flex flex-col gap-2"
                    >
                      <label htmlFor={"id1"} className="text-17xl font-medium">
                        Starting Value
                      </label>

                      <div className="relative flex-grow">
                        <input
                          id={"id2"}
                          type="number"
                          onChange={handleStatusChange}
                          value={status}
                          className="bg-yellow-100 border-primary-700 border border-opacity-25 text-primary-700 placeholder:text-primary-700 placeholder:text-opacity-40 rounded-full py-2 px-5 font-semibold text-5xl w-full"
                          placeholder={"Number"}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: selectedItems[0].id !== 3 ? "block" : "none",
                      }}
                      className="flex flex-col gap-2"
                    >
                      <label htmlFor={"id1"} className="text-17xl font-medium">
                        Goal
                      </label>

                      <div className="relative flex-grow">
                        <input
                          id={"id2"}
                          type="number"
                          onChange={handleGoalChange}
                          value={goal}
                          className="bg-yellow-100 border-primary-700 border border-opacity-25 text-primary-700 placeholder:text-primary-700 placeholder:text-opacity-40 rounded-full py-2 px-5 font-semibold text-5xl w-full"
                          placeholder={"Goal"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-8">
                  <div className="flex flex-col gap-2">
                    <label htmlFor={"id1"} className="text-17xl font-medium">
                      Wager
                    </label>

                    <div className="relative flex-grow">
                      <input
                        id={"id2"}
                        type="number"
                        onChange={handleWagerChange}
                        value={wager}
                        className="bg-yellow-100 border-primary-700 border border-opacity-25 text-primary-700 placeholder:text-primary-700 placeholder:text-opacity-40 rounded-full py-2 px-5 font-semibold text-5xl w-full"
                        placeholder={"Amount"}
                      />
                      <span className="absolute right-0 bg-yellow-700 font-medium text-17xl px-5 py-1 h-full rounded-full flex top-0 items-center justify-center ">
                        $
                      </span>
                    </div>
                  </div>

                  <div onClick={handleClick}>
                    <label
                      htmlFor="select-image"
                      className="flex px-5 py-3 bg-yellow-50 rounded-full cursor-pointer shadow-7xl gap-1"
                    >
                      <span>
                        <img
                          className="w-4 h-4"
                          src="/images/picture-svgrepo-com 1.svg"
                          alt=""
                        />
                      </span>
                      <span className="font-semibold text-7xl text-primary-700">
                        Add Video
                      </span>
                      <input
                        style={{ display: "none" }}
                        ref={inputRef}
                        accept="video/*"
                        name="Add Video"
                        type="file"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>

                  <div>{videoName == "" ? null : videoName}</div>

                  <p className="text-10xl font-medium">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    <br />
                    <br />
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                </div>
              </div>

              <div className="d-flex flex-row flex-wrap justify-content-around">
                <div
                  className="mt-3"
                  style={{
                    display: selectedItems[0].id == 0 ? "none" : "display",
                  }}
                >
                  <a className="mt-2">Deciding Winner</a>

                  <Select
                    onChange={handleDecidingWinner}
                    placeholder="Select Deciding Winner"
                    options={decidingWinnerOptions}
                  />
                </div>

                <div
                  className="mt-3"
                  style={{
                    display: selectedItems[0].id == 0 ? "none" : "display",
                  }}
                >
                  <a className="mt-2">Direction</a>

                  <Select
                    onChange={handleDirectionChange}
                    placeholder="Select Direction"
                    options={directionOptions}
                  />
                </div>

                <div
                  className="mt-3"
                  style={{
                    display:
                      options.length == 0 || selectedItems[0].id == 0
                        ? "none"
                        : "block",
                  }}
                >
                  <a className="mt-2">Measurements</a>

                  <Select
                    onChange={handleSelectChange}
                    placeholder="Select Measurement Tag"
                    options={options}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="lg:col-span-2">
            <h4 className="font-medium text-32xl mb-4">Payment</h4>
            <CardOptions />
            <div>
              <div className="flex flex-col gap-5">
                <div>
                  <div className="page-three">
                    <form className="page-three__form">
                      <CardElement options={CARD_ELEMENT_OPTIONS} />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-2">
          <LinkButton url="/new-competition" text="Back" />

          <button
            onClick={createCompetition}
            className="flex items-center justify-center gap-1 text-black bg-yellow-700 text-24xl rounded-full py-3.5 px-14 hover:no-underline"
          >
            Next
          </button>
        </div>
        <ToastContainer />
        <Modal
          isOpen={loaderChecker}
          onRequestClose={() => setLoaderChecker(false)}
          style={customStylesModalLoader}
        >
          <div>
            <Loader />
          </div>
        </Modal>
      </div>
    </main>
  );
}
