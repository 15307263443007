import "./AddMeasurements.scss";
import Input from "../../../../components/Input/Input";
import Button from "../../../../components/Button/Button";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useState } from "react";
import { toast } from "react-toastify";

const AddMeasurements = ({
  challengeId,
  getSubmissions,
  placeholder_text,
  challengeData,
}) => {
  const userData = useAuthUser();
  const [value, setValue] = useState("");
  const [startValue, setStartValue] = useState("");
  const [goalValue, setGoalValue] = useState("");
  const [participantCheck, setParticipantCheck] = useState(false);
  const authUser = useAuthUser();

  const makeSubmissions = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/submissions/`, {
        user: userData().user_id,
        challenge_id: challengeId,
        date: Date.now(),
        value: value,
      })
      .then((res) => {
        setValue("");
        getSubmissions();
      })
      .catch((err) => console.error(err));
  };

  const currentValue = () => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/challenges/${challengeId}/participantProgress`,
        {
          current: value,
          participant_id: authUser().user_id,
          team_id: null,
        }
      )
      .then((res) => {
        setGoalValue("");
        setStartValue("");
        setValue("");
        toast.success("Your current value is updated");
      })
      .catch((err) => console.error(err));
  };

  const goalStartValue = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/challenges/${challengeId}`, {
        goal: goalValue,
        participants: {
          start_measurement: startValue,
        },
      })
      .then((res) => {
        setGoalValue("");
        setStartValue("");
      })
      .catch((err) => console.error(err));
  };

  const StartValue = () => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/challenges/${challengeId}/participantProgress`,
        {
          start_measurement: startValue,
          participant_id: authUser().user_id,
          team_id: null,
        }
      )
      .then((res) => {
        setGoalValue("");
        setStartValue("");
        toast.success("Your start value is updated");
      })
      .catch((err) => console.error(err));
  };

  const addParticipant = () => {
    axios
      .patch(`${process.env.REACT_APP_API_URL}/challenges/${challengeId}/add`, {
        start_measurement: startValue,
        participant_id: authUser().user_id,
        team_id: null,
      })
      .then((res) => {
        setGoalValue("");
        setStartValue("");
        toast.success("You have been added as a participant");
        setParticipantCheck(true);
        window.location.reload();
      })
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <div
        style={{
          display: challengeData.participants.some(
            (elements) => elements.participant_id == authUser().user_id
          )
            ? "block"
            : "none",
        }}
        className="add-measurements"
      >
        <div className="d-flex justify-content-between">
          <h3 className="add-measurements__title">Update Current Value</h3>
          <div className="add-measurements__form">
            <Input
              placeholder={`Enter Current Value`}
              type="number"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <Button type="primary" text="Add" fn={() => currentValue()} />
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            challengeData.type == 0 &&
            challengeData.forOther == true &&
            challengeData.participants.some(
              (elements) => elements.participant_id == authUser().user_id
            )
              ? "block"
              : "none",
          marginTop: "2rem",
        }}
      >
        <div className="add-measurements">
          <div>
            <h3 className="add-measurements__title">Add your current value</h3>
          </div>
          <div className="add-measurements__form">
            <Input
              placeholder={`Enter Start Value`}
              type="number"
              value={startValue}
              onChange={(e) => setStartValue(e.target.value)}
            />

            <Button type="primary" text="Add" fn={() => StartValue()} />
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            challengeData.type == 1 &&
            challengeData.participants.some(
              (elements) => elements.participant_id == authUser().user_id
            )
              ? "block"
              : "none",
          marginTop: "2rem",
        }}
      >
        <div className="add-measurements">
          <div>
            <h3 className="add-measurements__title">
              (Me vs You) Add Start value
            </h3>
          </div>
          <div className="add-measurements__form">
            <Input
              placeholder={`Enter Start Value`}
              type="number"
              value={startValue}
              onChange={(e) => setStartValue(e.target.value)}
            />

            <Button type="primary" text="Add" fn={() => StartValue()} />
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            challengeData.type == 3 &&
            !challengeData.participants.some(
              (elements) => elements.participant_id == authUser().user_id
            ) &&
            participantCheck == false
              ? "block"
              : "none",
          marginTop: "2rem",
        }}
      >
        <div className="add-measurements">
          <div>
            <h3 className="add-measurements__title">
              Enter Starting value to add as participant
            </h3>
          </div>
          <div className="add-measurements__form">
            <Input
              placeholder={`Enter Start Value`}
              type="number"
              value={startValue}
              onChange={(e) => setStartValue(e.target.value)}
            />

            <Button type="primary" text="Add" fn={() => addParticipant()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMeasurements;
