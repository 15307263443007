import React from "react";
// @ts-ignore
import UserInfo from "../../components/common/UserInfo.tsx";
// @ts-ignore
import Card1 from "./Card.jsx";
import axios from "axios";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartBlank } from "@fortawesome/free-regular-svg-icons";
import {
  faHeart,
  faShare,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { useAuthUser } from "react-auth-kit";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import Modal from "react-modal";
import Form from "react-bootstrap/Form";
import Input from "../../components/Input/Input";
import ReactElasticCarousel from "react-elastic-carousel";
import {useNavigate} from "react-router-dom";

const Article = ({ getHomePosts, data, users, toast, getPosts, authUser }) => {
  const [dropdownVisible, setdropdownVisible] = useState(false);
  const [liked, setLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPost, setCurrentPost] = useState({});
  const [comment, setComment] = useState("");
  const [mergedComments, setMergedComments] = useState({});
  const [mergedLikes, setMergedLikes] = useState({});
  const [textFlags, setTextFlags] = useState(false);
  const navigate = useNavigate();

  let comments = [];

  for (let i = 0; i < data?.merged?.length; i++) {
    // console.log("data", data);
    comments = comments.concat(data.merged[i].comments);
  }

  const customStylesModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
    },
  };

  const filterComments = (comments) => {
    console.log(comments);
    var result = comments.filter((e) => !e.length);
    console.log(result);
    setMergedComments(result);
  };
  const filterLikes = (likes) => {
    var result = likes.filter((e) => e.length);
    setMergedLikes(result);
  };

  useEffect(() => {
    let likes = [];
    for (let i = 0; i < data?.merged?.length; i++) {
      // console.log("data", data);
      likes = likes.concat(data.merged[i].likes);
    }

    setLikesCount(likes.length);

    if (likes.find((user) => user.user === authUser().user_id)) {
      setLiked(true);
    }
  }, []);

  const likePost = () => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/posts/${data.merged[0].postId}/like`,
        {
          user: authUser().user_id,
          filter_id: data.merged[0].filerId,
        }
      )
      .then(() => {
        setLiked(true);
        setLikesCount(likesCount + 1);
        // getHomePosts();
      })
      .catch((err) => console.error(err));
  };

  const disLikePost = () => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/posts/${data.merged[0].postId}/dislike`,
        {
          user: authUser().user_id,
          filter_id: data.merged[0].filerId,
        }
      )
      .then(() => {
        setLiked(false);
        setLikesCount(likesCount - 1);
        // getHomePosts();
      })
      .catch((err) => console.error(err));
  };

  const setModal = (data, enable) => {
    console.log("data", data);
    setCurrentPost(data);
    setModalOpen(true);
  };

  const postComment = () => {
    console.log("Post", currentPost.merged[0]);
    axios
      .post(`${process.env.REACT_APP_API_URL}/posts/addComment`, {
        post_id: currentPost.merged[0].postId,
        user_id: authUser().user_id,
        comment: comment,
        post_user: currentPost.merged[0].user,
        filter_id: currentPost.merged[0].filerId,
      })
      .then((res) => {
        getHomePosts();
      })
      .catch((err) => console.error(err));
    setModalOpen(false);
    setComment("");
  };

  const handleChange = (event) => {
    setComment(event.target.value);

    // console.log("value is:", event.target.value);
  };

  return (
    <section>
      <div className="bg-yellow-700 rounded-lg py-3 px-4">
        <div>
          <UserInfo
            image={data.merged[0].avatar}
            name={data.merged[0].userName}
            id={data._id}
            active={new Date(data.merged[0].date)
              .toISOString()
              .replace(/T.*/, "")
              .split("-")
              .reverse()
              .join("-")}
          />
        </div>

        <div className="grid md:grid-cols-5 gap-5">
          <div className="d-flex flex-wrap rounded-t-xl rounded-b-lg">
            <ReactElasticCarousel showArrows={false} enableMouseSwipe={false}>
              {data.merged.map((item) => {
                return (
                  <div style={{ width: "100%" }}>
                    <div className="bg-primary-700 rounded-t-xl rounded-b-lg mt-2 mb-2">
                      {item?.image &&
                      [".jpg", ".jpeg", ".png", ".jfif"].some((char) =>
                        item.image.endsWith(char)
                      ) ? (
                        <img
                          style={{ maxHeight: "47vh", objectFit: "cover" }}
                          className="w-full  rounded-lg"
                          src={item.image}
                          alt=""
                        />
                      ) : null}
                      {item?.image &&
                      ![".jpg", ".jpeg", ".png", ".jfif"].some((char) =>
                        item.image.endsWith(char)
                      ) ? (
                        <video
                          style={{ maxHeight: "47vh" }}
                          className="w-full rounded-lg"
                          controls
                          src={item.image}
                          alt=""
                        />
                      ) : null}
                      <div className="rounded-b-lg py-2 px-3 flex justify-between gap-2">
                          <p
                          onClick={()=>{
                            if(item.challengeKey){
                              navigate(`/competition/${item.challengeKey}`)
                            }
                          }}
                           className="font-medium cursor-pointer text-5xl text-white p-3 mb-1">
                            {console.log(item)}
                            { data?.merged[0]?.challengeName
                ? "#" + data?.merged[0]?.challengeName
                : ""}
                          </p>
                        <div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </ReactElasticCarousel>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            overflow: "hidden",
            overflowWrap: "break-word",
          }}
        >
          {data.merged.map((data) => {
            return (
              <p className="text-dark font-light text-23xl mb-4">
                {textFlags === false ? data?.text?.slice(0, 150) : data.text}
                <span
                  onClick={() => setTextFlags(!textFlags)}
                  className="mx-6 cursor-pointer"
                >
                  {textFlags === false
                    ? data.text?.length > 150
                      ? "Read More..."
                      : ""
                    : "Read Less..."}
                </span>
              </p>
            );
          })}
        </div>

        <div className="flex gap-3 items-center">
          <button
            onClick={() => (liked ? disLikePost() : likePost())}
            type="button"
            className="flex gap-1 items-center"
          >
            <FontAwesomeIcon
              className="post-content-like-heart"
              icon={liked ? faHeart : faHeartBlank}
            />
            <span className="font-medium text-7xl"> {likesCount}</span>
          </button>
          <button
            onClick={() => setModal(data, false)}
            type="button"
            className="flex gap-1 items-center"
          >
            <img
              className="w-6 h-6"
              src="/images/icons8-topic-50 3.png"
              alt=""
            />
            <span className="font-medium text-7xl">{comments.length}</span>
          </button>
          <button type="button" className="flex gap-1 items-center">
            <img
              className="w-[18px] h-[18px]"
              src="/images/icons8-send-60 13.png"
              alt=""
            />
          </button>
        </div>

        {comments
          ? comments.map((value) => {
              return (
                <div class=" p-3 mt-2 bg-yellow-50">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="user d-flex flex-row align-items-center w-100">
                      <img
                        style={{ marginRight: "2%" }}
                        src={value?.image}
                        width="30"
                        class="user-img rounded-circle"
                      />
                      <span class="d-flex flex-column">
                        <small
                          style={{ marginRight: "2%" }}
                          class="font-weight-bold text-primary"
                        >
                          {value?.userName}
                        </small>
                        <small class="font-weight-bold">{value?.comment}</small>
                      </span>
                    </div>

                    {/* <div>
              <small>2 days ago</small>
            </div> */}
                  </div>
                </div>
              );
            })
          : ""}
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customStylesModal}
        contentLabel="Place Bet"
      >
        <div>
          <Input
            style={{ width: "100%" }}
            placeholder="Enter Comment"
            type="text"
            id="bid"
            onChange={handleChange}
            value={comment}
          />

          <button
            onClick={() => postComment()}
            style={{ float: "right" }}
            className=" mt-4 flex items-center justify-center gap-1 bg-primary-700 text-yellow-50 text-7xl font-semibold rounded-full py-3 px-7 sm:px-14 hover:no-underline shadow-7xl"
          >
            Comment
          </button>
        </div>
      </Modal>
    </section>
  );
};

export default Article;
