import "./Competitions.scss";
import { useState, useEffect, useMemo } from "react";
import PageContent from "../../components/PageContent/PageContent";
import Select from "react-select";
import Bike from "../../assets/images/types/bike.svg";
import Button from "../../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartBlank } from "@fortawesome/free-regular-svg-icons";
import { feHeart } from "@fortawesome/free-regular-svg-icons";
import Form from "react-bootstrap/Form";
import Input from "../../components/Input/Input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaChevronDown } from "react-icons/fa";
import ReactRoundedImage from "react-rounded-image";
import moment from "moment";
import NA from "../../assets/images/image-not-available.jpg";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "../../components/Loader/loader.jsx";
import {
  faHeart,
  faShare,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import {
  LinearProgress,
  createTheme,
  ThemeProvider,
  makeStyles,
  Box,
} from "@material-ui/core";
import ProgressBar from "react-bootstrap/ProgressBar";
import WagePayment from "../../utils/WagePayment";
import SideBar from "../../components/Side-bar-fixed/Side-bar-fixed";
import useScrollReset from "../../hooks/useScrollReset.ts";
import { faComputerMouse } from "@fortawesome/free-solid-svg-icons";

const Competitions = ({ categories, types }) => {
  const [loaderChecker, setLoaderChecker] = useState(false);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const [isEnabled, setIsEnabled] = useState(false);
  const [latestCompetitions, setLatestCompetitions] = useState(null);
  const [pastCompetitions, setPastCompetitions] = useState(null);
  const [userCompetitions, setUserCompetitions] = useState(null);
  const [currentCompetitions, setCurrentCompetitions] = useState({});
  const [dataCounts, setDataCounts] = useState({
    count:0,
    isloadMore:true,
  });
  const [amount, setAmount] = useState(0);
  const [result, setResult] = useState(false);
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const [data, setData] = useState({
    latest: null,
    past: null,
  });

  const [userBase, setUserBase] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [typeOptions, setTypeOptions] = useState([
    { value: -1, label: "All Types" },
  ]);
  const [categoryOptions, setCategoryOptions] = useState([
    { value: -1, label: "All Categories" },
  ]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([
    { value: -1, label: "All Sub Categories" },
  ]);

  const [selectedType, setSelectedType] = useState(typeOptions[0]);
  const [selectedCategory, setSelectedCategory] = useState(categoryOptions[0]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    subCategoryOptions[0]
  );
  const [DataForDisplay, setDataForDisplay] = useState([]);
  // const [startIndex, setStartIndex] = useState(0);
  // const [endingIndex, setEndingIndex] = useState(20);
  const [pageNo ,setPageNo] = useState(0);
  const [pageSize ,setPageSize] = useState(9);

  const customStylesModalLoader = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      border: "none !important",
      background: "none !important",
      overflow: "none !important",
    },
  };

  useEffect(() => {
    document.title = "Competitions";
  }, []);

  useEffect(() => {
    let typeObj = [];
    typeObj.push(typeOptions[0]);
    types.forEach((type, i) => {
      typeObj.push({ value: i, label: type });
    });
    setTypeOptions(typeObj);

    let categoryObj = [];
    categoryObj.push(categoryOptions[0]);
    categories.forEach((category, i) => {
      categoryObj.push({ value: i, label: category.name });
    });
    setCategoryOptions(categoryObj);

    let subCategoryObj = [];
    subCategoryObj.push(subCategoryOptions[0]);
    categories[0].sub_categories.forEach((sub_category, i) => {
      subCategoryObj.push({ value: i, label: sub_category.name });
    });
    setSubCategoryOptions(subCategoryObj);
  }, []);

  useEffect(() => {
    setLoaderChecker(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/challenges/latest?pageNo=${pageNo}&pageSize=${pageSize}`)
      .then((res) => {
        // console.log(res.data)
        setDataCounts({...dataCounts, count: res.data.count, isloadMore: res.data.loadMore});
        setLatestCompetitions(res.data.data);

        return axios.get(`${process.env.REACT_APP_API_URL}/challenges/past`);
      })
      .then((res) => {
        setPastCompetitions(res.data);

        return axios.get(
          `${process.env.REACT_APP_API_URL}/challenges/user/${
            authUser().user_id
          }?pageNo=${pageNo}&pageSize=${5}`
        );
      })
      .then((res) => {
        setDataCounts({...dataCounts, count: res.data.count, isloadMore: res.data.loadMore});
        setUserCompetitions(res.data.data);
      })

      .catch((err) => console.log(err));
  }, [pageNo, pageSize]);

  const getCompetitions = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/challenges/latest?pageNo=${pageNo}&pageSize=${pageSize}`)
      .then((res) => {
        setLatestCompetitions(res.data);
        return axios.get(`${process.env.REACT_APP_API_URL}/challenges/past`);
      })
      .then((res) => {
        setPastCompetitions(res.data);
        return axios.get(
          `${process.env.REACT_APP_API_URL}/challenges/user/${
            authUser().user_id
          }?pageNo=${pageNo}&pageSize=${pageSize}`
        );
      })
      .then((res) => {
        setUserCompetitions(res.data);
      })

      .catch((err) => console.err(err));
  };

  // useEffect(() => {
  //   if (latestCompetitions) {
  //     latestCompetitions.map((item) => {
  //       getCount(item._id);
  //     });
  //   }
  // }, [latestCompetitions]);

  useEffect(() => {
    if (userCompetitions && latestCompetitions && pastCompetitions) {
      setLoaderChecker(false);
      if (userBase === 0) {
        setData({
          latest: userCompetitions
            .filter((comp) => comp.status === true)
            .filter((comp) =>
              selectedType.value === -1
                ? comp.status === true
                : comp.type === selectedType.value
            )
            .filter((comp) =>
              selectedCategory.value === -1
                ? comp.status === true
                : comp.category === selectedCategory.value
            )
            .filter((comp) =>
              selectedSubCategory.value === -1
                ? comp.status === true
                : comp.sub_category === selectedSubCategory.value
            ),
          past: userCompetitions
            .filter((comp) => comp.status === false)
            .filter((comp) =>
              selectedType.value === -1
                ? comp.status === false
                : comp.type === selectedType.value
            )
            .filter((comp) =>
              selectedCategory.value === -1
                ? comp.status === false
                : comp.category === selectedCategory.value
            )
            .filter((comp) =>
              selectedSubCategory.value === -1
                ? comp.status === false
                : comp.sub_category === selectedSubCategory.value
            ),
        });
        setDataForDisplay({
          latest: userCompetitions
            .filter((comp) => comp.status === true)
            .filter((comp) =>
              selectedType.value === -1
                ? comp.status === true
                : comp.type === selectedType.value
            )
            .filter((comp) =>
              selectedCategory.value === -1
                ? comp.status === true
                : comp.category === selectedCategory.value
            )
            .filter((comp) =>
              selectedSubCategory.value === -1
                ? comp.status === true
                : comp.sub_category === selectedSubCategory.value
            ),
          past: userCompetitions
            .filter((comp) => comp.status === false)
            .filter((comp) =>
              selectedType.value === -1
                ? comp.status === false
                : comp.type === selectedType.value
            )
            .filter((comp) =>
              selectedCategory.value === -1
                ? comp.status === false
                : comp.category === selectedCategory.value
            )
            .filter((comp) =>
              selectedSubCategory.value === -1
                ? comp.status === false
                : comp.sub_category === selectedSubCategory.value
            ),
        });
      } else {
        setData({
          latest: latestCompetitions
            .filter((comp) => comp.status === true)
            .filter((comp) =>
              selectedType.value === -1
                ? comp.status === true
                : comp.type === selectedType.value
            )
            .filter((comp) =>
              selectedCategory.value === -1
                ? comp.status === true
                : comp.category === selectedCategory.value
            )
            .filter((comp) =>
              selectedSubCategory.value === -1
                ? comp.status === true
                : comp.sub_category === selectedSubCategory.value
            ),
          past: pastCompetitions
            .filter((comp) => comp.status === false)
            .filter((comp) =>
              selectedType.value === -1
                ? comp.status === false
                : comp.type === selectedType.value
            )
            .filter((comp) =>
              selectedCategory.value === -1
                ? comp.status === false
                : comp.category === selectedCategory.value
            )
            .filter((comp) =>
              selectedSubCategory.value === -1
                ? comp.status === false
                : comp.sub_category === selectedSubCategory.value
            ),
        });
        setDataForDisplay({
          latest: latestCompetitions
            .filter((comp) => comp.status === true)
            .filter((comp) =>
              selectedType.value === -1
                ? comp.status === true
                : comp.type === selectedType.value
            )
            .filter((comp) =>
              selectedCategory.value === -1
                ? comp.status === true
                : comp.category === selectedCategory.value
            )
            .filter((comp) =>
              selectedSubCategory.value === -1
                ? comp.status === true
                : comp.sub_category === selectedSubCategory.value
            ),
          past: pastCompetitions
            .filter((comp) => comp.status === false)
            .filter((comp) =>
              selectedType.value === -1
                ? comp.status === false
                : comp.type === selectedType.value
            )
            .filter((comp) =>
              selectedCategory.value === -1
                ? comp.status === false
                : comp.category === selectedCategory.value
            )
            .filter((comp) =>
              selectedSubCategory.value === -1
                ? comp.status === false
                : comp.sub_category === selectedSubCategory.value
            ),
        });
      }
      // setStartIndex(0);
      // setEndingIndex(20);
    }
  }, [
    userBase,
    latestCompetitions,
    pastCompetitions,
    userCompetitions,
    selectedType,
    selectedCategory,
    selectedSubCategory,
  ]);

  const customStylesModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
    },
  };

  // const getCount = (comp) => {
  //   axios({
  //     method: "post",
  //     url: `${process.env.REACT_APP_API_URL}/wager/getBid`,
  //     headers: {},
  //     data: {
  //       id: comp._id,
  //     },
  //   }).then((res) => {
  //     setLoaderChecker(false);
  //     console.log(res.data);
  //     setCount({
  //       bidCount: {
  //         win: res?.data?.bidCount?.win,
  //         lose: res?.data?.bidCount?.lose,
  //       },
  //     });
  //   });
  // };

  const likeCompetition = (comp, likeDislike) => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/challenges/saveLike`,
      headers: {},
      data: {
        user_id: authUser().user_id,
        challenge_id: comp._id,
        like: likeDislike,
      },
    }).then((res) => {
      if (likeDislike) {
        comp.likes.push({ user: authUser().user_id, _id: comp._id });
        setDataForDisplay({ ...DataForDisplay });
        toast.success("Competition has been added to favorite");
      } else {
        const index = comp.likes.findIndex((object) => {
          return object.user == authUser().user_id;
        });
        comp.likes.splice(index, 1);
        setDataForDisplay({ ...DataForDisplay });
        toast.success("Competition has been removed from favorite");
      }
    });
  };

  const competitionDetailPage = (comp) => {
    console.log(comp.participants[0].participant_id);
    console.log(authUser().user_id);
    if (
      comp.participants.some(
        (elements) => elements.participant_id == authUser().user_id
      ) ||
      comp.user == authUser().user_id ||
      comp.type == 3
    ) {
      navigate(`/competition/${comp._id}`);
      return;
    }
    toast.warning(
      "In order to view details of this match you have either follow this user or Select an outcome of the competition"
    );
  };

  const setModal = (comp, enable) => {
    console.log("Comp", comp);
    setCurrentCompetitions(comp);
    setIsEnabled(enable);
    setModalOpen(true);
  };

  const submitWage = async (paymentId) => {
    setModalOpen(false);
    console.log("Payment", paymentId);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/wager/setBid`,
      headers: {},
      data: {
        user_id: authUser().user_id,
        challenge_id: currentCompetitions._id,
        bid: amount,
        expected_result: isEnabled ? true : false,
        stripe_id: paymentId,
      },
    });

    setCurrentCompetitions({});
    getCompetitions();
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#725095" : "#725095",
      background: state.isSelected ? "#F0EFFF" : "white",
      ":hover": {
        background: "#F0EFFF",
      },
    }),
  };

  return (
    <section className="competitions">
      {/* <Sidebar /> */}
      <PageContent>
        <div className="competitions__wrapper">
          <div className="competitions__header">
            <div className="competitions__switch">
              <span
                className={
                  userBase === 0
                    ? "competitions__switch-value competitions__switch-value--active"
                    : "competitions__switch-value"
                }
                onClick={() => setUserBase(0)}
              >
                My Competitions
              </span>
              <span
                className={
                  userBase === 1
                    ? "competitions__switch-value competitions__switch-value--active"
                    : "competitions__switch-value"
                }
                onClick={() => setUserBase(1)}
              >
                All Competitions
              </span>
            </div>
            <Link to="/new-competition">
              <Button
                className="competitions__header-button"
                text="New Competition"
                type="primary"
              />
            </Link>
          </div>
          <div className="competitions__filter">
            <Select
              options={typeOptions}
              styles={customStyles}
              placeholder="Select Type"
              value={selectedType}
              onChange={(e) => setSelectedType(e)}
            />
            <Select
              options={categoryOptions}
              styles={customStyles}
              placeholder="Select Category"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e)}
            />
            <Select
              options={subCategoryOptions}
              styles={customStyles}
              placeholder="Select Sub Category"
              value={selectedSubCategory}
              onChange={(e) => setSelectedSubCategory(e)}
            />
          </div>

          <div className="competitions__latest">
            <h2>Latest Competitions</h2>
            <div className="competitions__competitions">
              {DataForDisplay.latest
                ? DataForDisplay.latest
                    .map((comp) => {
                      return (
                        <div
                          style={{
                            width: "35rem",
                            boxShadow: "1px 2px 9px rgb(225 220 221)",
                          }}
                        >
                          <div className="row">
                            <div
                              className="col-9"
                              style={{
                                backgroundImage:
                                  "linear-gradient(to right, #FFCC66, #AE7400)",
                                padding: "2%",
                              }}
                            >
                              {/* <Link to={`/competition/${comp._id}`}> */}
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "5px",
                                  }}
                                >
                                  <div className="d-flex flex-row justify-content-between">
                                    <FontAwesomeIcon
                                      onClick={() =>
                                        likeCompetition(
                                          comp,
                                          comp.likes.find(
                                            (user) =>
                                              user.user === authUser().user_id
                                          )
                                            ? false
                                            : true
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                      className="post-content-like-heart"
                                      icon={
                                        comp.likes.find(
                                          (user) =>
                                            user.user == authUser().user_id
                                        )
                                          ? faHeart
                                          : faHeartBlank
                                      }
                                    />

                                    <h3>
                                      <FaChevronDown
                                        style={{ color: "white" }}
                                      />
                                    </h3>
                                  </div>
                                </div>

                                <div className="d-flex justify-content-center">
                                  <ReactRoundedImage
                                    image={
                                      comp?.userDetails[0]?.profilePicture.includes(
                                        "amazonaws"
                                      )
                                        ? comp?.userDetails[0]?.profilePicture
                                        : NA
                                    }
                                    imageWidth="50"
                                    imageHeight="50"
                                    roundedSize="0"
                                    borderRadius="70"
                                  />
                                </div>

                                <div className=" d-flex text-white justify-content-center">
                                  <p className="card-info">
                                    <strong>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          navigate("/profile", {
                                            state: {
                                              id: comp?.user,
                                              name: comp?.userDetails[0]?.userName,
                                            },
                                          })
                                        }
                                      >
                                        {comp?.userDetails[0]?.userName}
                                      </a>
                                    </strong>
                                  </p>
                                </div>

                                <div className=" d-flex text-white justify-content-center">
                                  <p>
                                    {comp.type == 0
                                      ? "You vs You"
                                      : comp.type == 1
                                      ? "Me vs You"
                                      : comp.type == 2
                                      ? "Us vs Them"
                                      : comp.type == 3
                                      ? "Free For all"
                                      : "N/A"}
                                  </p>
                                </div>

                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => competitionDetailPage(comp)}
                                >
                                  <div className=" d-flex text-white justify-content-center">
                                    <p>{comp.name}</p>
                                  </div>

                                  <div className=" d-flex text-white justify-content-center">
                                    <p>
                                      {comp.category == 0
                                        ? "Fitness"
                                        : comp.category == 1
                                        ? "Finance"
                                        : comp.category == 2
                                        ? "Habits"
                                        : comp.category == 3
                                        ? "Learning"
                                        : "None"}
                                    </p>
                                  </div>

                                  <div
                                    style={{ height: "7%" }}
                                    className="d-flex justify-content-center text-white justify-content-around card-info"
                                  >
                                    <p className="card-info">Starting Date</p>
                                    <p class="ml-4 card-info ">
                                      {moment(new Date(comp.start_date)).format(
                                        "MM-DD-YY"
                                      )}
                                    </p>
                                  </div>

                                  <div
                                    style={{ height: "7%" }}
                                    className="d-flex justify-content-center justify-content-around text-white card-info"
                                  >
                                    <p className="card-info">Ending Date</p>
                                    <p className="card-info">
                                      {moment(new Date(comp.end_date)).format(
                                        "MM-DD-YY"
                                      )}
                                    </p>
                                  </div>

                                  <div className=" d-flex justify-content-around text-white">
                                    <span
                                      className="d-flex flex-column justify-content-center"
                                      style={{ width: "min-content" }}
                                    >
                                      <p className="text-center text-white sidelabel-data">
                                        10
                                      </p>
                                      <p class="text-center text-white sidelabel">
                                        Starting Points
                                      </p>
                                    </span>

                                    <span
                                      className="d-flex flex-column justify-content-center"
                                      style={{ width: "min-content" }}
                                    >
                                      <p className="text-center text-white sidelabel-data">
                                        {comp.wager}
                                      </p>
                                      <p class="text-center text-white sidelabel">
                                        Current Points
                                      </p>
                                    </span>

                                    <span
                                      className="d-flex flex-column justify-content-center"
                                      style={{
                                        width: "min-content",
                                        transform: "translate(0%, -12%)",
                                      }}
                                    >
                                      <p className="text-center text-white sidelabel-data">
                                        {comp.goal}
                                      </p>
                                      <p class="text-center text-white sidelabel">
                                        Goal
                                      </p>
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <hr
                                    style={{ color: "white", color: "white" }}
                                    class="my-2"
                                  ></hr>
                                </div>
                              </div>
                              {/* </Link> */}
                              <div className="mt-4 d-flex justify-content-center text-white">
                                <p className="card-info">
                                  <strong>
                                    Will {comp?.userDetails[0]?.userName} Succeed?
                                  </strong>
                                </p>
                              </div>

                              <div className=" mb-2 d-flex justify-content-center">
                                <button
                                  onClick={() => setModal(comp, true)}
                                  style={{ marginRight: "10%" }}
                                  type="button"
                                  class="btn btn-light btn-circle btn-xl"
                                >
                                  Yes
                                </button>

                                <button
                                  onClick={() => setModal(comp, false)}
                                  type="button"
                                  class="btn btn-light btn-circle btn-xl"
                                >
                                  No
                                </button>
                              </div>

                              <div className="mb-5 d-flex flex-row justify-content-center">
                                <p
                                  className="circle"
                                  style={{ marginTop: "-10px" }}
                                >
                                  Yes
                                </p>
                                <LinearProgress
                                  style={{ width: "50%", marginTop: "3px" }}
                                  variant="determinate"
                                  value={
                                    (comp?.bidCount[0].win /
                                      (comp?.bidCount[0].win +
                                        comp?.bidCount[0].lose)) *
                                    100
                                  }
                                />
                                <p
                                  className="circle"
                                  style={{
                                    marginTop: "-10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {comp?.bidCount[0].win}
                                </p>
                              </div>

                              <div className="mb-5 d-flex flex-row justify-content-center">
                                <p
                                  className="circle"
                                  style={{ marginTop: "-10px" }}
                                >
                                  No
                                </p>
                                <LinearProgress
                                  style={{ width: "50%", marginTop: "3px" }}
                                  variant="determinate"
                                  value={
                                    (comp?.bidCount[0].lose /
                                      (comp?.bidCount[0].win +
                                        comp?.bidCount[0].lose)) *
                                    100
                                  }
                                />
                                <p
                                  className="circle"
                                  style={{
                                    marginTop: "-10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {comp?.bidCount[0].lose}
                                </p>
                              </div>
                            </div>
                            <div className="col-3 d-flex flex-wrap align-content-around justify-content-center">
                              <span className="d-flex flex-column transformText justify-content-center w-100">
                                <p class="text-center text-dark sidelabel-data">
                                  0-0
                                </p>
                                <p class="text-center text-dark sidelabel">
                                  Record
                                </p>
                              </span>

                              <span className="d-flex flex-column transformText justify-content-center w-100">
                                <p class="text-center text-dark sidelabel-data">
                                  {comp?.bidCount[0].win} -{" "}
                                  {comp?.bidCount[0].lose}
                                </p>
                                <p class="text-center text-dark sidelabel">
                                  Investing Record
                                </p>
                              </span>

                              <span className="d-flex flex-column transformText justify-content-center w-100">
                                <p class="text-center text-dark sidelabel-data">
                                  50
                                </p>
                                <p class="text-center text-dark sidelabel">
                                  Total Winning
                                </p>
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                : ""}
            </div>
            <div class="d-flex flex-row justify-content-end">
              {
                Number(pageNo) > 0 ? (
                  <button
                className=" mt-4 flex items-center justify-center gap-1 bg-primary-700 text-yellow-50 text-7xl font-semibold rounded-full py-3 px-7 sm:px-14 hover:no-underline shadow-7xl"
                style={{ marginRight: "20px" }}
                type="button"
                disabled={Number(pageNo) <= 0}
                onClick={() => {
                 setPageNo(pageNo - 1);
                  <useScrollReset />;
                }}
              >
                Previous Page
              </button>
                ) : ""
              }
             {
              dataCounts.isloadMore === true ? (
                <button
                className=" mt-4 flex items-center justify-center gap-1 bg-primary-700 text-yellow-50 text-7xl font-semibold rounded-full py-3 px-7 sm:px-14 hover:no-underline shadow-7xl"
                type="button"
                disabled={dataCounts.isloadMore === false}
                onClick={() => {
                  setPageNo(pageNo + 1);
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                Next Page
              </button>
              ) : ""
             }
            </div>
          </div>
          <div className="competitions__latest">
            <h2>Past Competitions</h2>
            <div className="competitions__competitions">
              {data.past
                ? data.past.map((comp) => {
                    return (
                      <Link to={`/competition/${comp._id}`}>
                        <div className="competitions__competition">
                          <div className="competitions__competition-main competitions__competition-main--inactive">
                            <div className="competitions__competition-info">
                              <p className="competitions__competition-category">
                                {categories[comp.category].name}
                              </p>
                              <p className="competitions__competition-pot">
                                ${comp.participants.length * comp.wager}
                              </p>
                            </div>
                            <img
                              className="competitions__competition-image"
                              src={Bike}
                              alt="bike"
                            />
                          </div>
                          <p className="competitions__competition-name">
                            {comp.name}
                          </p>
                        </div>
                      </Link>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          style={customStylesModal}
          contentLabel="Place Bet"
        >
          <div className="add-member__modal">
            <h2>Place A Bet</h2>
            <Form>
              <Form.Check
                type="switch"
                disabled
                defaultChecked={isEnabled}
                id="custom-switch"
                label="Will They Win?"
              />
            </Form>
            <Input
              placeholder="Wager ($ USD)"
              type="number"
              id="bid"
              onChange={(value) => setAmount(value.target.value)}
            />
            <div>
              <Elements stripe={stripePromise}>
                <WagePayment handleSubmit={submitWage} />
              </Elements>
            </div>

            {/* <Button
            text="Place Bet"
            type="primary"
            fn={() => {
              setModalOpen(false);
              toast.success("Bet Placed");
              submitWage(amount, currentCompetitions._id, isEnabled);
            }}
          /> */}
          </div>
        </Modal>

        <Modal
          isOpen={loaderChecker}
          onRequestClose={() => setLoaderChecker(false)}
          style={customStylesModalLoader}
        >
          <div>
            <Loader />
          </div>
        </Modal>
        <ToastContainer />
      </PageContent>
    </section>
  );
};

export default Competitions;
