import React from "react";
import { Link, useNavigate } from "react-router-dom";

interface UserInfoProps {
  image: string;
  name: string;
  active: string;
  id: String;
}

export default function UserInfo({ image, name, active, id}: UserInfoProps) {
  const navigate = useNavigate();

  const navigateProfile = () => {
    navigate("./profile", { state: { id: id, name: name } });
  };

  return (
    <div style={{marginTop:"10px"}} className="flex gap-4">
      <img className="w-12 h-12" src={image} alt={name} />
      <div className="flex flex-col flex-grow">
        <a style={{ cursor: "pointer" }} onClick={navigateProfile}>
          <strong className="font-medium text-23xl">{name}</strong>
        </a>
        <span className="text-gray-70 text-18xl">{active}</span>
        
      </div>
    </div>
  );
}
