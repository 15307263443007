import PageContent from "../../components/PageContent/PageContent";
import Sidebar from "../../components/Side-bar-fixed/Side-bar-fixed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button/Button";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Post from "./Post/Post";
import ImageUploading from "react-images-uploading";
import { v4 as uuidv4 } from "uuid";
import "../../../src/style/main.css";
import Article from "./Article.jsx";
import WhoFollow from "./WhoFollow.jsx";
import Event from "./event/Event.tsx";
import Friends from "./friends/Friends.tsx";
import "./Home.scss";
import Loader from "../../components/Loader/loader.jsx";

import CreatePost from "./CreatePost.jsx";
import InfiniteScroll from "react-infinite-scroll-component";
import { TailSpin } from "react-loader-spinner";

const Home = () => {
  // const [posts, setPosts] = useState(null);
  const [loaderChecker, setLoaderChecker] = useState(false);
  const [Follow, setFollow] = useState(null);
  const [users, setUsers] = useState([]);
  const [newPostText, setNewPostText] = useState("");
  const authUser = useAuthUser();
  const [postImage, setPostImage] = useState([]);
  const [DataForDisplay, setDataForDisplay] = useState([]);
  const [expanded, setExpanded] = useState(5);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [dataCounts, setDataCounts] = useState({
    count: 0,
    isloadMore: true,
  });
  const Posts = [];

  useEffect(() => {
    document.title = "Home";
  }, []);

  useEffect(() => {
    if(pageNo === 0){
      setLoaderChecker(true);
    getPosts();
    setPageNo((prev) => prev + 1);
    }
  }, []);
  
  const loadContent = async () => {
    await axios
    .get(
      `${process.env.REACT_APP_API_URL}/posts?pageNo=${pageNo}&pageSize=${pageSize}`
      )
      .then((res) => {
        res?.data?.data?.map((item) => {
          Posts.push(item);
        });
        if (DataForDisplay?.length) {
          setDataForDisplay([...DataForDisplay, ...Posts]);
          setDataCounts({
            ...dataCounts,
            count: res.data.count,
            isloadMore: res.data.loadMore,
          });
          setLoaderChecker(false);
          // setPageNo(prev => prev +1)
        } else {
          setDataForDisplay(Posts);
          setDataCounts({
            ...dataCounts,
            count: res.data.count,
            isloadMore: res.data.loadMore,
          });
          setLoaderChecker(false);
        }
      })
      .catch((err) => console.log(err));
      setPageNo((prev) => prev + 1);
    };

  const getPosts = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/posts?pageNo=0&pageSize=${pageSize}`
      )
      .then((res) => {
        setDataCounts({
          ...dataCounts,
          count: res.data.count,
          isloadMore: res.data.loadMore,
        });
        setDataForDisplay(res.data.data);
        setLoaderChecker(false);
      })
      .catch((err) => console.log(err));
  };

  const showMore = () => {
    setPageNo(pageNo + 1);
  };
  const showLess = () => {
    getPosts();
    setPageNo(0);
  };

  return (
    <div>
      <div className="container">
        <div className="flex flex-col-reverse lg:!grid lg:grid-cols-3 xl:grid-cols-4 gap-5 mb-5">
          <div className="flex flex-col gap-6">
            <div id="style-3" className="who-follow-side">
              <WhoFollow />
              <Event />
            </div>
          </div>
          <div className="lg:col-span-2">
            <CreatePost getHomePosts={getPosts} showLess={showLess} />
            <div style={{ display: loaderChecker ? "block" : "none" }}>
              <Loader />
            </div>
            <div className="flex flex-col gap-5 mb-3">
              <InfiniteScroll
                dataLength={DataForDisplay?.length}
                next={loadContent}
                hasMore={dataCounts.isloadMore}
                className="flex flex-col gap-5 mb-3"
                loader={pageNo > 1 ? (
                  <TailSpin
                  height="80"
                  width="80"
                  color="#2f8cff"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
                ): null}
              >
                {DataForDisplay
                  ? DataForDisplay.map((post) => {
                      return (
                        <Article
                          getHomePosts={getPosts}
                          data={post}
                          users={users}
                          toast={toast}
                          getPosts={getPosts}
                          authUser={authUser}
                          key={uuidv4()}
                        />
                      );
                    })
                  : ""}
              </InfiniteScroll>
              {/* {dataCounts.isloadMore === true && (
                <button
                  style={{ display: !loaderChecker ? "block" : "none" }}
                  type="button"
                  onClick={() => {
                    // setDataForDisplay(expanded ? posts.slice(0, 5) : posts);
                    // setExpanded(!expanded);
                    // setPageNo()
                    showMore();
                  }}
                >
                  Show More
                </button>
              )}
              {dataCounts.isloadMore === false && (
                <button
                  style={{ display: !loaderChecker ? "block" : "none" }}
                  type="button"
                  onClick={() => {
                    // setDataForDisplay(expanded ? posts.slice(0, 5) : posts);
                    // setExpanded(!expanded);
                    // setPageNo()
                    showLess();
                  }}
                >
                  Show Less
                </button>
              )} */}
              {/* <div className="d-flex flex-row justify-content-end">
              {
                Number(pageNo) > 0 ? (
                  <button
                className=" mt-4 flex items-center justify-center gap-1 bg-primary-700 text-yellow-50 text-7xl font-semibold rounded-full py-3 px-7 sm:px-14 hover:no-underline shadow-7xl"
                style={{ marginRight: "20px" }}
                type="button"
                disabled={Number(pageNo) <= 0}
                onClick={() => {
                 setPageNo(pageNo - 1);
                  <useScrollReset />;
                }}
              >
                Previous Page
              </button>
                ) : ""
              }
             {
              dataCounts.isloadMore === true ? (
                <button
                className=" mt-4 flex items-center justify-center gap-1 bg-primary-700 text-yellow-50 text-7xl font-semibold rounded-full py-3 px-7 sm:px-14 hover:no-underline shadow-7xl"
                type="button"
                disabled={dataCounts.isloadMore === false}
                onClick={() => {
                  setPageNo(pageNo + 1);
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                Next Page
              </button>
              ) : ""
             }
              </div> */}
            </div>
          </div>
          <div className="hidden xl:block">
            <div id="style-3" className="friends-side">
              <Friends />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
