import { useState } from "react";
import "./style.css";

const SelectOptions = ({
  competitionTag,
  handleSelectChange,
  selectOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message , setMessage] = useState("");
  const comName = selectOptions.filter(name => name.value === competitionTag)
  console.log(comName)
  return (
    <>
      <button
        className="relative py-2 px-2 select-tag inline-flex items-center gap-2 bg-yellow-50  rounded-full cursor-pointer"
        type="button"
        onClick={()=> setIsOpen(!isOpen)}
      >
       {
        competitionTag ? comName[0].label : (
            <>
            Select Competition Tag
            <svg
              className="ml-2 w-4 h-4"
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
            </>
        )
       }
      </button>

      {/* <!-- Dropdown menu --> */}
      {isOpen && (
        <div className="absolute z-50 mt-20 w-60 bg-yellow-50 rounded shadow dark:bg-gray-700">
          <div className="p-3">
            <div className="relative">
            <div className="flex flex-grow items-center gap-2 bg-white rounded-full py-2 px-3">
            <button type="button">
              <img className="h-4" src="/images/Group 6.svg" alt="" />
            </button>
            <div>
              <input
                type="text"
                onChange={(e)=> setMessage(e.target.value)}
                value={message}
                className="placeholder:text-gray-400 text-gray-200 bg-transparent flex-grow w-full text-3xl outline-in"
                placeholder="Search for Competition Tag"
              />
            </div>
          </div>
             
            </div>
          </div>
          <ul className="over-size px-3 pb-3">
            {selectOptions.filter(i => i?.label?.toLowerCase().includes(message)).map((item) => (
              <li key={item.value}>
                <div className="flex items-center gap-6">
                  <input
                    id={item.value}
                    type="radio"
                    value={item.value}
                    onClick={(e)=>{
                    //    console.log(e.target.value, "here")
                       handleSelectChange(e.target.value)
                       setIsOpen(false)
                    }}
                    name="default-radio"
                    className="w-5 h-5"
                  />
                  <label htmlFor={item.value} className="px-3 py-2 text-gray-700 text-5xl">
                    {item.label}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div className="flex items-center p-3 select-tag bg-gray-50 border-t border-gray-200 dark:border-gray-600 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-red-500 hover:underline">
            Apply Competition Tag
          </div>
        </div>
      )}
    </>
  );
};

export default SelectOptions;
